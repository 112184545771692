
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faChevronUp, faChevronDown, faArrowDown, faArrowUp} from '@fortawesome/free-solid-svg-icons'
library.add([faChevronUp, faChevronDown, faArrowDown, faArrowUp]);

import { mapGetters } from "vuex";
import _ from "lodash";
import Switcher from "../components/app/switcher.vue";

const PAGE_SIZE = 10;
const OPEN_SUBSCRIPTION_PROMO_MODAL_EVENT = 'open-subscription-promo-modal';

export default {
    name: "index",
    components: {
        Switcher,
        FontAwesomeIcon
    },
    meta: {
        footer: false,
        titlebar: false,
    },
    head: {
        // TODO asm remove
        bodyAttrs: {
            class: "overflow-hidden"
        }
    },

    data() {
        const advanced_filters_default = {
            years_range: [1776, new Date().getFullYear() + 1],
            bedrooms_from: "",
            bedrooms_to: "",
            bathrooms_from: "",
            bathrooms_to: "",
            arv_from: "",
            arv_to: "",
            arv_percentage_from: "",
            arv_percentage_to: "",
            gross_margin_from: "",
            gross_margin_to: "",
            days_from: "",
            days_to: "",
            radius: 100,
            keywords: "",
            property_types: [],
            property_tags: [],
            favorites: false,
            filter_by_locations: false,
            status: "available",
        };

        const filters_default = {
            prices_range: [0, 'Any'],
            location: {
                latitude: null,
                longitude: null,
                zoom: null,
                viewport: null,
            },
            state: null,
            is_verified: false,
        };
        return {
            filters: {
                ..._.cloneDeep(filters_default),
            },
            advanced_filters: _.cloneDeep(advanced_filters_default),
            slider2: {
                width: "auto",
                height: 2,
                direction: "horizontal",
                dotSize: 20,
                eventType: "auto",
                lazy: true,
                tooltip: "hover",
                tooltipDir: "bottom",
                processStyle: { "backgroundColor": "#274abb" },
            },
            filters_default,
            advanced_filters_default,
            pageMarker: PAGE_SIZE,
            loaded: false,
            show_more_filters: false,
            sort_prop: 'hotness',
            visibleList: [],
            highlighted_properties: [],
            token: this.getToken(),
        };
    },
    computed: {
        ...mapGetters({
            all_properties: "properties",
            all_count: "all_count",
            isPremiumBuyer: 'isPremiumBuyer',
        }),
        states() {
            return this.$config.cur_site_config.account
                    .account_states?.map(state_id => this.$config.all_states.find(st => st.id === state_id))
                ?? this.$config.all_states
            ;
        },
        properties() {
            let filters = this.filters_all;
            let res = this.all_properties.filter(
                property => property.price >= filters.prices_range[0]
                && (filters.prices_range[1] === 'Any' || property.price <= filters.prices_range[1])
                && ((filters.years_range[0] === this.minMaxYears[0]) || property.year_built >= filters.years_range[0])
                && ((filters.years_range[1] === this.minMaxYears[1]) || property.year_built <= filters.years_range[1])
                && (!filters.bedrooms_from || Number(property.bedrooms) >= Number(filters.bedrooms_from))
                && (!filters.bedrooms_to || Number(property.bedrooms) <= Number(filters.bedrooms_to))
                && (!filters.bathrooms_from || Number(property.bathrooms) >= Number(filters.bathrooms_from))
                && (!filters.bathrooms_to || Number(property.bathrooms) <= Number(filters.bathrooms_to))
                && (!filters.arv_from || Number(property.arv_estimate) >= Number(filters.arv_from))
                && (!filters.arv_to || Number(property.arv_estimate) <= Number(filters.arv_to))
                && (!filters.arv_percentage_from || Number(property.arv_percentage) >= Number(filters.arv_percentage_from))
                && (!filters.arv_percentage_to || Number(property.arv_percentage) <= Number(filters.arv_percentage_to))
                && (!filters.gross_margin_from || property.gross_margin >= Number(filters.gross_margin_from))
                && (!filters.gross_margin_to || property.gross_margin <= Number(filters.gross_margin_to))
                && (!filters.days_from || property.days_published >= Number(filters.days_from))
                && (!filters.days_to || property.days_published <= Number(filters.days_to))
                && (!filters.keywords || property.title.toLowerCase().includes(filters.keywords.toLowerCase()) || property.title.toLowerCase() === filters.keywords.toLowerCase())
                && (!filters.state || property.state_code === filters.state)
                && (!filters.property_types.length || filters.property_types.map(pt => pt.id).includes(property.property_type_id))
                && (!filters.property_tags.length || (property.tags && filters.property_tags.some(pt => property.tags.includes(pt.id))))
                && (!filters.favorites || property.is_favorite)
                && (!filters.is_verified || property.is_verified)
                && (!filters.filter_by_locations || !this.$auth.loggedIn || this.$auth.user.geotags.some(geotag_id => property.geotag_hierarchy_ids?.includes(geotag_id)))
                && (this.$auth.loggedIn || !filters.location.latitude || this.pointInsideCircle(property))
            );

            if (this.sort_prop === "hotness") {
                const recentProperties = res.filter(property => property.days_published <= 7);
                const otherProperties = res.filter(property => property.days_published > 7);

                recentProperties.sort((a, b) => {
                    return b.hotness >= a.hotness ? 1 : -1
                });

                otherProperties.sort((a, b) => {
                    return b.hotness >= a.hotness ? 1 : -1
                });

                res = [...recentProperties, ...otherProperties];

            }

            if (this.sort_prop === "latest") {
                res.sort((a, b) => (b.days_published >= a.days_published) ? -1 : 1);
            }

            if (this.sort_prop === "most_viewed") {
                res.sort((a, b) => (b.views >= a.views) ? 1 : -1);
            }

            return res;
        },
        propertiesListPaged() {
            let res = this.properties.filter(item => this.visibleList.includes(item.id)).slice(0, this.pageMarker);

            res.forEach(i => i.opened = false);
            if (this.highlighted_properties.length>0) {
                res.forEach(i => i.opened = this.highlighted_properties.includes(i.id));
            }

            return res;
        },
        propertiesLoaderDisabled() {
            return this.pageMarker >= this.visibleList.length;
        },
        filters_all() {
            return {
                ...this.filters,
                ...this.advanced_filters,
                account_slug: this.$route.params.account_slug,
            };
        },
        prices_list() {
            let default_prices = this.all_properties?.map(property => property.price) || [];
            if (this.$auth.loggedIn) {
                default_prices = [...default_prices, ...this.getPricesFromBuyBox];
            }
            let prices = [...default_prices, ...this.$settings.dicts.price, ...this.filters.prices_range];
            prices = [...new Set(prices)];
            var index = prices.indexOf('Any');
            if (index !== -1) {
                prices.splice(index, 1);
            }
            prices.sort((a, b) => a - b);
            prices.push('Any');

            this.filters.prices_range = [...this.filters.prices_range];

            return prices;
        },
        minMaxPrices() {
            return [0, 'Any'];
        },
        getPricesFromBuyBox() {
            let {asking_price_min, asking_price_max} = this.$auth.user;
            return [
                asking_price_min ?? 0,
                asking_price_max ?? 'Any'
            ];
        },
        getYearsFromBuyBox() {
            let {year_built_min, year_built_max} = this.$auth.user;
            return [
                year_built_min ?? 1776,
                year_built_max ?? (new Date().getFullYear() + 1)
            ];
        },
        years_list() {
            let years = this.all_properties
                .map(property => property.year_built)
                .filter(Boolean)
            ;

            years.push(new Date().getFullYear() + 1);
            years.push(1776);

            years = [...years, ...this.advanced_filters.years_range];
            if (this.$auth.loggedIn) {
                years = [...years, ...this.getYearsFromBuyBox];
            }

            years = [...new Set(years)];
            years.sort((a, b) => a - b);

            this.advanced_filters.years_range = [...this.advanced_filters.years_range];

            return years;
        },
        minMaxYears() {
            return [1776, new Date().getFullYear() + 1];
        },
        mapCenter() {
            let location = this.filters.location;
            if (location?.latitude) {
                return [
                    Number(location.latitude),
                    Number(location.longitude),
                    Number(location.zoom),
                    location.viewport,
                ]
            } else {
                return this.$settings.defaultMapCenter;
            }
        },
        count_filter_calc() {
            return this.visibleList.length;
        },
    },
    ssrComputedCache: ["all_properties", "properties"],
    watch: {
        "$auth.loggedIn": function (new_val) {
            this.initFilters();

            // update token reactively
            this.token = this.getToken();
        },
        filters_all: {
            handler: async function (new_val, old_val) {
                if (!this.loaded || JSON.stringify(new_val) === JSON.stringify(old_val)) return;

                await this.$store.dispatch("load_properties", {
                    status: new_val.status,
                    account_slug: new_val.account_slug
                });
            },
            deep: true
        },
        'filters.location': function (val) {
            if (val.latitude===null) this.$refs["ac-address"].value = "";
        },
        'filters.prices_range.0': function (new_val, old_val) {
            if (new_val === 'Any') this.filters.prices_range[0] = old_val;
        },
        properties: {
            handler: async function (new_val, old_val) {
                if (this.$auth.loggedIn) return;

                if (JSON.stringify(new_val.map(r => r.id)) !== JSON.stringify(old_val.map(r => r.id))) {
                    this.visibleList = new_val.map(r => r.id);
                }
            },
            deep: true
        },
    },
    async mounted() {
        setTimeout(() => this.loaded = true, 1000);
        this.initAutocomplete();
    },
    updated() {
        // fix for vue-slider-component
        setTimeout(() => window.dispatchEvent(new Event("resize")), 50);
    },
    activated() {
        // fix for vue-slider-component
        setTimeout(() => window.dispatchEvent(new Event("resize")), 50);
    },
    async fetch() {
        await this.$store.dispatch("load_properties", {
            status: this.filters_all.status,
            account_slug: this.filters_all.account_slug
        });

        this.initFilters();
        this.filters.state = this.states.find(state => state.slug.toLowerCase() === this.$route.params.state?.toLowerCase())?.code ?? "";

        this.visibleList = this.properties.map(r => r.id);
    },
    methods: {
        initAutocomplete() {
            if (!window.google) {
                this.$nuxt.$once("googleMapApi:init", this.initAutocomplete);
                return;
            }

            const options = {
                componentRestrictions: { country: ["us", "pr"] },
                fields: ["geometry", "types", "address_components"],
            };

            const autocomplete = new google.maps.places.Autocomplete(this.$refs["ac-address"], options);
            autocomplete.addListener("place_changed", () => {
                let place = autocomplete.getPlace();
                if (!place || !place.geometry) return;

                let zoom = 12;

                if (place.types && place.types.length > 0) {
                    if (place.types.includes('locality')) {
                        zoom = 8;
                    } else if (place.types.includes('administrative_area_level_1')) {
                        zoom = 6;
                    }
                }

                this.filters.location = {
                    latitude: place.geometry.location.lat(),
                    longitude: place.geometry.location.lng(),
                    zoom,
                    viewport: place.geometry.viewport ?? null,
                };

                // state filter
                place.address_components.forEach(addr => {
                    switch (addr.types[0]) {
                        case "administrative_area_level_1":
                            this.filters.state = addr.short_name;
                        break;
                    }
                });
            });
        },
        initFilters() {
            this.onFiltersClear();
            if (this.$auth.loggedIn) {
                this.onBuyBoxApply();
                if (this.$route.query.digest) {
                    this.advanced_filters.filter_by_locations = true;
                    this.sortBy("latest");
                    this.show_more_filters = true;
                }
            }
        },
        onFiltersClear() {
            this.filters = _.cloneDeep(this.filters_default);
            this.advanced_filters = _.cloneDeep(this.advanced_filters_default);
            this.filters.prices_range = this.minMaxPrices;
            this.filters.state = null;
            this.advanced_filters.years_range = this.minMaxYears;
        },
        onBuyBoxApply() {
            this.advanced_filters.property_types = this.$auth.user.property_types;
            this.advanced_filters.bathrooms_from = this.$auth.user.baths_min;
            this.advanced_filters.bedrooms_from = this.$auth.user.beds_min;
            this.advanced_filters.arv_from = this.$auth.user.arv_min;
            this.advanced_filters.arv_percentage_from = this.$auth.user.arv_min_percent;
            this.advanced_filters.gross_margin_from = this.$auth.user.gross_margin_min;
            this.advanced_filters.years_range = this.getYearsFromBuyBox;
            this.filters.prices_range = this.getPricesFromBuyBox;
        },
        sortBy(option = "latest") {
            if (!['hotness', 'most_viewed', 'latest'].includes(option)) {
                return
            }
            if (this.sort_prop === option)
                return

            this.sort_prop = option
        },
        loadNextProperties() {
            this.pageMarker += PAGE_SIZE;
        },
        updateVisibleList(list) {
            this.visibleList = list;
        },
        highlightOpenedProperties(list) {
            this.highlighted_properties = list;
        },
        clear(elements) {
            if (typeof elements === 'string' && elements === 'address') {
                this.$refs["ac-address"].value = "";
                this.filters.location = {
                    latitude: null,
                    longitude: null,
                    zoom: null,
                    viewport: null,
                };
                this.filters.state = null;
            }
            else elements.forEach(el => this.value[el] = "");
        },
        zoomOutClearFilter() {
            this.$refs["ac-address"].value = "";
            this.filters.state = null;
        },
        pointInsideCircle(property) {

            const {latitude: point_lat, longitude: point_long} = this.filters.location;

            let {latitude, longitude} = property;

            const degreeInKmY = 40008/360;
            const degreeInKmX = (40075/360)*Math.cos(latitude*Math.PI/180);

            let dx = Math.abs(longitude-point_long);
            let x = dx*degreeInKmX;

            let dy = Math.abs(latitude-point_lat);
            let y = dy*degreeInKmY;

            let r = 100 / 0.621371192; // 100 mile radius

            let res = x*x+y*y <= r*r;

            return res;
        },
        saveBuyBox() {
            console.log(this.filters_all);
        },
        handlePremiumBuyerCheckoutModalOpen(val) {
            if (this.isPremiumBuyer === null) return;

            if (this.isPremiumBuyer === false) {
                window.dispatchEvent(new Event(OPEN_SUBSCRIPTION_PROMO_MODAL_EVENT));
            }
            else {
                this.filters.is_verified = val;
            }
        },
        handleSubscriptionPurchased() {
            this.$store.dispatch("setIsPremium", true);
            this.filters.is_verified = true;
        },
        getToken() {
            const auth = this.$auth.strategy.token.get() || '';
            const [, token] = auth.split(/\s+/); //NOTE: the stored token contains already Bearer schema, but we require the bare token only

            return token || '';
        },
    },
    filters: {
        price (value) {
            if (!Array.isArray(value)) value = [value];
            return value.map(value => {
                let settings = {
                    divider: 1000,
                    toFix: 0,
                    metricPrefix: 'k',
                    divider2: 1,
                };
                if (Number(value) >= 1000000) {
                    settings.divider = 100000;
                    settings.toFix = 1;
                    settings.metricPrefix = 'M';
                    settings.divider2 = 10;
                }
                let n = Math.round(Number(value) / settings.divider);
                n = Number(n / settings.divider2);
                if (isNaN(n)) {
                    return value;
                } else {
                    return '$' + n.toLocaleString('en-US') + settings.metricPrefix;
                }
            }).join(' - ');
        }
    }
}
