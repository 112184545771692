export const FormField = () => import('../../components/form/field.vue' /* webpackChunkName: "components/form-field" */).then(c => wrapFunctional(c.default || c))
export const AuthAgreeWithTerms = () => import('../../components/auth/agree-with-terms.vue' /* webpackChunkName: "components/auth-agree-with-terms" */).then(c => wrapFunctional(c.default || c))
export const AuthTokenPage = () => import('../../components/auth/auth-token-page.vue' /* webpackChunkName: "components/auth-token-page" */).then(c => wrapFunctional(c.default || c))
export const AuthForgotPassword = () => import('../../components/auth/forgot-password.vue' /* webpackChunkName: "components/auth-forgot-password" */).then(c => wrapFunctional(c.default || c))
export const AuthRegisterFormLimited = () => import('../../components/auth/register-form-limited.vue' /* webpackChunkName: "components/auth-register-form-limited" */).then(c => wrapFunctional(c.default || c))
export const AuthRegisterForm = () => import('../../components/auth/register-form.vue' /* webpackChunkName: "components/auth-register-form" */).then(c => wrapFunctional(c.default || c))
export const AuthSocial = () => import('../../components/auth/social.vue' /* webpackChunkName: "components/auth-social" */).then(c => wrapFunctional(c.default || c))
export const AppBackdrop = () => import('../../components/app/backdrop.vue' /* webpackChunkName: "components/app-backdrop" */).then(c => wrapFunctional(c.default || c))
export const AppBadgeListed = () => import('../../components/app/badge-listed.vue' /* webpackChunkName: "components/app-badge-listed" */).then(c => wrapFunctional(c.default || c))
export const AppErrorMessage = () => import('../../components/app/error-message.vue' /* webpackChunkName: "components/app-error-message" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/app/footer.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/app/header.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const AppImg = () => import('../../components/app/img.vue' /* webpackChunkName: "components/app-img" */).then(c => wrapFunctional(c.default || c))
export const AppLoader = () => import('../../components/app/loader.vue' /* webpackChunkName: "components/app-loader" */).then(c => wrapFunctional(c.default || c))
export const AppLocalLoader = () => import('../../components/app/local-loader.vue' /* webpackChunkName: "components/app-local-loader" */).then(c => wrapFunctional(c.default || c))
export const AppLogin = () => import('../../components/app/login.vue' /* webpackChunkName: "components/app-login" */).then(c => wrapFunctional(c.default || c))
export const AppMobileOverlay = () => import('../../components/app/mobile-overlay.vue' /* webpackChunkName: "components/app-mobile-overlay" */).then(c => wrapFunctional(c.default || c))
export const AppNuxtLink = () => import('../../components/app/nuxt-link.vue' /* webpackChunkName: "components/app-nuxt-link" */).then(c => wrapFunctional(c.default || c))
export const AppSwitcher = () => import('../../components/app/switcher.vue' /* webpackChunkName: "components/app-switcher" */).then(c => wrapFunctional(c.default || c))
export const AppTextLubenda = () => import('../../components/app/text-lubenda.vue' /* webpackChunkName: "components/app-text-lubenda" */).then(c => wrapFunctional(c.default || c))
export const AppTitle = () => import('../../components/app/title.vue' /* webpackChunkName: "components/app-title" */).then(c => wrapFunctional(c.default || c))
export const ModalConfirmPhone = () => import('../../components/modal/confirm-phone.vue' /* webpackChunkName: "components/modal-confirm-phone" */).then(c => wrapFunctional(c.default || c))
export const ModalReviewsList = () => import('../../components/modal/reviews-list.vue' /* webpackChunkName: "components/modal-reviews-list" */).then(c => wrapFunctional(c.default || c))
export const ModalVerifyAction = () => import('../../components/modal/verify-action.vue' /* webpackChunkName: "components/modal-verify-action" */).then(c => wrapFunctional(c.default || c))
export const PostDealAddress = () => import('../../components/post-deal/address.vue' /* webpackChunkName: "components/post-deal-address" */).then(c => wrapFunctional(c.default || c))
export const PostDealDetails = () => import('../../components/post-deal/details.vue' /* webpackChunkName: "components/post-deal-details" */).then(c => wrapFunctional(c.default || c))
export const PostDealImages = () => import('../../components/post-deal/images.vue' /* webpackChunkName: "components/post-deal-images" */).then(c => wrapFunctional(c.default || c))
export const PostDealInfo = () => import('../../components/post-deal/info.vue' /* webpackChunkName: "components/post-deal-info" */).then(c => wrapFunctional(c.default || c))
export const PostDealModal = () => import('../../components/post-deal/modal.vue' /* webpackChunkName: "components/post-deal-modal" */).then(c => wrapFunctional(c.default || c))
export const PostDealOverview = () => import('../../components/post-deal/overview.vue' /* webpackChunkName: "components/post-deal-overview" */).then(c => wrapFunctional(c.default || c))
export const PostDealSuccess = () => import('../../components/post-deal/success.vue' /* webpackChunkName: "components/post-deal-success" */).then(c => wrapFunctional(c.default || c))
export const PostDealTerms = () => import('../../components/post-deal/terms.vue' /* webpackChunkName: "components/post-deal-terms" */).then(c => wrapFunctional(c.default || c))
export const PropertiesAdvancedFilters = () => import('../../components/properties/advanced-filters.vue' /* webpackChunkName: "components/properties-advanced-filters" */).then(c => wrapFunctional(c.default || c))
export const PropertiesFavCard = () => import('../../components/properties/fav-card.vue' /* webpackChunkName: "components/properties-fav-card" */).then(c => wrapFunctional(c.default || c))
export const PropertiesFavHeart = () => import('../../components/properties/fav-heart.vue' /* webpackChunkName: "components/properties-fav-heart" */).then(c => wrapFunctional(c.default || c))
export const PropertiesMapInfoWindow = () => import('../../components/properties/map-info-window.vue' /* webpackChunkName: "components/properties-map-info-window" */).then(c => wrapFunctional(c.default || c))
export const PropertiesMap = () => import('../../components/properties/map.vue' /* webpackChunkName: "components/properties-map" */).then(c => wrapFunctional(c.default || c))
export const PropertiesOfferCard = () => import('../../components/properties/offer-card.vue' /* webpackChunkName: "components/properties-offer-card" */).then(c => wrapFunctional(c.default || c))
export const PropertiesOpenhouseCard = () => import('../../components/properties/openhouse-card.vue' /* webpackChunkName: "components/properties-openhouse-card" */).then(c => wrapFunctional(c.default || c))
export const PropertiesPropertyListingItem = () => import('../../components/properties/property-listing-item.vue' /* webpackChunkName: "components/properties-property-listing-item" */).then(c => wrapFunctional(c.default || c))
export const PropertyComparables = () => import('../../components/property/comparables.vue' /* webpackChunkName: "components/property-comparables" */).then(c => wrapFunctional(c.default || c))
export const PropertyContact = () => import('../../components/property/contact.vue' /* webpackChunkName: "components/property-contact" */).then(c => wrapFunctional(c.default || c))
export const PropertyFeatures = () => import('../../components/property/features.vue' /* webpackChunkName: "components/property-features" */).then(c => wrapFunctional(c.default || c))
export const PropertyGallery = () => import('../../components/property/gallery.vue' /* webpackChunkName: "components/property-gallery" */).then(c => wrapFunctional(c.default || c))
export const PropertyMakeOffer0stepFinancing = () => import('../../components/property/make-offer-0step-financing.vue' /* webpackChunkName: "components/property-make-offer0step-financing" */).then(c => wrapFunctional(c.default || c))
export const PropertyMakeOffer1stepDetails = () => import('../../components/property/make-offer-1step-details.vue' /* webpackChunkName: "components/property-make-offer1step-details" */).then(c => wrapFunctional(c.default || c))
export const PropertyMakeOffer2stepFunds = () => import('../../components/property/make-offer-2step-funds.vue' /* webpackChunkName: "components/property-make-offer2step-funds" */).then(c => wrapFunctional(c.default || c))
export const PropertyMakeOffer3stepAcceptance = () => import('../../components/property/make-offer-3step-acceptance.vue' /* webpackChunkName: "components/property-make-offer3step-acceptance" */).then(c => wrapFunctional(c.default || c))
export const PropertyNeighborhood = () => import('../../components/property/neighborhood.vue' /* webpackChunkName: "components/property-neighborhood" */).then(c => wrapFunctional(c.default || c))
export const PropertyOffers = () => import('../../components/property/offers.vue' /* webpackChunkName: "components/property-offers" */).then(c => wrapFunctional(c.default || c))
export const PropertyOpenHouse = () => import('../../components/property/open-house.vue' /* webpackChunkName: "components/property-open-house" */).then(c => wrapFunctional(c.default || c))
export const UserBuybox = () => import('../../components/user/buybox.vue' /* webpackChunkName: "components/user-buybox" */).then(c => wrapFunctional(c.default || c))
export const UserGeotagsLocations = () => import('../../components/user/geotags-locations.vue' /* webpackChunkName: "components/user-geotags-locations" */).then(c => wrapFunctional(c.default || c))
export const UserPremiumRequired = () => import('../../components/user/premium-required.vue' /* webpackChunkName: "components/user-premium-required" */).then(c => wrapFunctional(c.default || c))
export const UserRequestDetails = () => import('../../components/user/request-details.vue' /* webpackChunkName: "components/user-request-details" */).then(c => wrapFunctional(c.default || c))
export const UserSidebar = () => import('../../components/user/sidebar.vue' /* webpackChunkName: "components/user-sidebar" */).then(c => wrapFunctional(c.default || c))
export const PropertyGetFinancingStep1Details = () => import('../../components/property/get-financing/step1-details.vue' /* webpackChunkName: "components/property-get-financing-step1-details" */).then(c => wrapFunctional(c.default || c))
export const PropertyGetFinancingStep2Apply = () => import('../../components/property/get-financing/step2-apply.vue' /* webpackChunkName: "components/property-get-financing-step2-apply" */).then(c => wrapFunctional(c.default || c))
export const PropertyGetFinancingStep3Thanks = () => import('../../components/property/get-financing/step3-thanks.vue' /* webpackChunkName: "components/property-get-financing-step3-thanks" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
