
import {faCreditCard as farCreditCard} from "@fortawesome/free-regular-svg-icons";
import {library} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {faBullhorn} from "@fortawesome/free-solid-svg-icons";
library.add([farCreditCard, faBullhorn]);

export default {
    data() {
        return {
            userMenuActive: false,
            logo_url: null,
            intercom_user_hash: null,
            token: this.getToken()
        }
    },
    components: {
        FontAwesomeIcon,
    },
    computed: {
        userPhoto() {
            return this.$auth.user.photo ? `url(${this.$auth.user.photo})` : `url(${require('~/assets/images/no-user-image.gif')})`;
        },
        showSocials() {
            return this.$config.cur_site_config?.facebook
                || this.$config.cur_site_config?.linkedin
                || this.$config.cur_site_config?.twitter
                || this.$config.cur_site_config?.instagram
                || this.$config.cur_site_config?.youtube
                || this.$config.cur_site_config?.pinterest;
        },
        showEmail() {
            return this.$config.cur_site_config.email;
        },
        showPhone() {
            return this.$config.cur_site_config.phone && !this.$config.cur_site_config.account.is_super;
        },
        longContacts() {
            return this.showEmail && this.showPhone;
        },
        pusher() {
            return JSON.stringify(this.$config.pusher);
        }
    },
    created() {
        if (this.$auth.loggedIn && process.server && this.$config.intercom_secret_key) {
            const crypto = require('crypto');
            const secret_key = this.$config.intercom_secret_key;
            const user_id = this.$auth.user.id.toString();
            const hash = crypto.createHmac('sha256', secret_key).update(user_id).digest('hex');

            this.intercom_user_hash = hash;
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.$auth.loggedIn && this.$intercom) {
                this.intercomStart()
            }
        })
    },
    watch: {
        '$auth.loggedIn'(val) {
            // control intercom
            if (this.$intercom) {
                if (val) {
                    this.intercomStart()
                }
                else {
                    this.$intercom.shutdown();
                }
            }

            // update token reactively
            this.token = this.getToken();
        }
    },
    async fetch() {
        try {
            await this.$axios.head(this.$config.cur_site_config.img_logo);
            this.logo_url = this.$config.cur_site_config.img_logo;
        } catch (error) {
            this.logo_url = require('~/assets/images/logo.svg');
        }
    },
    methods: {
        intercomStart() {
            let {first_name, last_name, id, email, created_at} = this.$auth.user;
            this.$intercom.boot({
                api_base: "https://api-iam.intercom.io",
                autoBoot: false,
                debug: this.$config.app_env === 'developer' ? true : false,
                updateOnPageRoute: true,
                user_hash: this.intercom_user_hash,
                name: first_name + " " + last_name,
                user_id: id,
                email,
                created_at
            });
        },
        toggleUserMenu() {
            this.userMenuActive = !this.userMenuActive;
        },
        closeUserMenu() {
            this.userMenuActive = false;
        },
        async logout() {
            await this.$auth.logout();
        },
        getToken() {
            const auth = this.$auth.strategy.token.get() || '';
            const [, token] = auth.split(/\s+/); //NOTE: the stored token contains already Bearer schema, but we require the bare token only

            return token || '';
        },
    }
}
