
import BadgeListed from "../app/badge-listed.vue";
import PremiumRequired from "../user/premium-required.vue";

export default {
    components: {
        PremiumRequired,
        BadgeListed,
    },
    props: {
        hostname: {
            type: String,
            default: ""
        },
        property: Object,
        scale: null,
    },
    data() {
        return {
            cssClasses: {

            }
        }
    },
    computed: {
        first_line_address() {
            return this.property.county ? this.property.county : this.property.city;
        },
        second_line_address() {
            let address = "";
            if (this.property.county)
                address = [this.property.city, this.property.state_code, this.property.zip].filter(Boolean).join(', ');
            else
                address = [this.property.state_code, this.property.zip].filter(Boolean).join(', ');

            return address;
        },
    },
    methods: {
        toggleFavorite(property_id, add) {
            this.$store.dispatch("toggleFavorite", {property_id, add});
        }
    }
}
